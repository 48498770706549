/* Swiper sliders */

// Home Hero
const heroSwiper = new Swiper('.swiper.home-hero-swiper', {
  // Default parameters
  slidesPerView: 1,
  autoplay: {
    delay: 5000,
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
})


// Home - Applications & Pillars
const swiper = new Swiper('.swiper.home-applications-swiper, .swiper.home-pillars-swiper', {
  // Default parameters
  slidesPerView: 1,
  spaceBetween: 40,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },

  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 320px
    768: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    // when window width is >= 480px
    992: {
      slidesPerView: 3,
      spaceBetween: 30
    }
  }
})

/* Loader Management */
function loaderActivate (start) {
  let loader = document.querySelector('.techsub-overlay')
  if (start) {
    loader.style.display = 'block';
  } else {
    loader.style.display = 'none';
  }
}

/* Check Form Validity */

// Fetch all the forms we want to apply custom Bootstrap validation styles to
let forms = document.querySelectorAll('.needs-validation')

// Loop over them and prevent submission
Array.prototype.slice.call(forms)
  .forEach(function (form) {
    form.addEventListener('submit', function (event) {
      if (!form.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      }

      form.classList.add('was-validated')
    }, false)
  })


/* Calculator Requests */

// Select first option by default for all select
let selectTags = document.getElementsByTagName("select");
for(let i = 0; i < selectTags.length; i++) {
  selectTags[i].selectedIndex = 0;
}

// Config
const baseURL = 'https://89.144.100.166/ar/calculator';
const CSRF = document.querySelector("meta[name='csrf']").getAttribute("content")

// Request configurations
let configurations = {
  method: 'GET',
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-TOKEN": CSRF,
    "Content-Type": "application/json",
  },
};


// Telework Calculator
let teleworkCalculatorButton = document.querySelector('.calculate-telework');
if (teleworkCalculatorButton) {
  teleworkCalculatorButton.addEventListener('click', function () {

    // Get NO Vehicles
    let main = teleworkCalculatorButton.previousElementSibling.querySelector('select[name="telework-select-main"]').value;
    let number = teleworkCalculatorButton.previousElementSibling.querySelector('select[name="telework-select-number"]').value;
    let activity = teleworkCalculatorButton.previousElementSibling.querySelector('select[name="telework-select-activity"]').value;

    // Request
    fetch(baseURL + `/${activity}/${number}/${main}`, configurations).then(response => response.json()).then(data => {
      loaderActivate(true);

      if(data.status) {
        teleworkCalculatorButton.previousElementSibling.querySelector('.response-message').innerHTML = data.value;

      } else {
        teleworkCalculatorButton.previousElementSibling.querySelector('.response-message').innerHTML = data.message;
      }

      loaderActivate(false);
    }).catch(err => {
      alert(err)
    });
  })
}


// Muqeem Calculator
let muqeemCalculatorButton = document.querySelector('.calculate-muqeem');
if (muqeemCalculatorButton) {
  muqeemCalculatorButton.addEventListener('click', function () {

    // Get NO Vehicles
    let service = muqeemCalculatorButton.previousElementSibling.querySelector('select[name="muqeem-select-service"]').value;
    let number = muqeemCalculatorButton.previousElementSibling.querySelector('select[name="muqeem-select-number"]').value;

    // Request
    fetch(baseURL + `/${service}/${number}`, configurations).then(response => response.json()).then(data => {
      loaderActivate(true);

      if(data.status) {
        muqeemCalculatorButton.previousElementSibling.querySelector('.response-message').innerHTML = data.value;

      } else {
        muqeemCalculatorButton.previousElementSibling.querySelector('.response-message').innerHTML = data.message;
      }

      loaderActivate(false);
    }).catch(err => {
      alert(err)
    });
  })
}


// TTM Calculator
let ttmCalculatorButton = document.querySelector('.calculate-ttm');
if (ttmCalculatorButton) {
  ttmCalculatorButton.addEventListener('click', function () {

    // Get NO Vehicles
    let numberOfVehicles = ttmCalculatorButton.previousElementSibling.querySelector('select[name="ttm-select-number"]').value;

    // Request
    fetch(baseURL + `/ttm/${numberOfVehicles}`, configurations).then(response => response.json()).then(data => {
      loaderActivate(true);

      if(data.status) {
        ttmCalculatorButton.previousElementSibling.querySelector('.response-message').innerHTML = data.value;

      } else {
        ttmCalculatorButton.previousElementSibling.querySelector('.response-message').innerHTML = data.message;
      }

      loaderActivate(false);
    }).catch(err => {
      alert(err)
    });
  })
}



